html {
  height: 100%;
}

#starsBox {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0.5;
}

#starsBox > span {
  display: inline-block;
  width: auto;
  position: absolute;
  border-radius: 100%;
  transition: 10s linear;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  animation: animStar 20s linear infinite;
}
#stars:after {
  content: ' ';
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 711px 300px #fff, 1146px 1076px #fff, 22px 799px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 226px 1254px #fff, 989px 1928px #fff, 463px 1562px #fff;
  animation: animStar 30s linear infinite;
}
#stars2:after {
  content: ' ';
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 226px 1254px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 219px 1528px #fff, 1978px 1339px #fff, 820px 32px #fff;
  animation: animStar 50s linear infinite;
}

#stars3:after {
  content: ' ';
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 219px 1528px #fff, 1978px 1339px #fff, 820px 32px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
