@font-face {
  font-family: 'Raleway';
  src: './styles/font/Raleway-Regular.ttf';
}

* {
  font-family: 'Raleway';
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
}

body {
  background: radial-gradient(ellipse, #1b2735 0%, #090a0f 100%);
}

.app {
  min-height: 100vh;
}

.app-content {
  min-height: 100vh;
}

a,
h1,
h2,
h3,
h4,
hr,
p,
span,
.card-hdr {
  color: rgb(200, 200, 200);
}

a:hover {
  color: #fafafa;
  cursor: pointer;
}

.card-box {
  max-width: 40%;
}

.card-box > img {
  border: 1px solid rgb(187, 187, 187);
  border-radius: 10%;
  height: auto;
  max-width: 70%;
}

.about-me-desc {
  max-width: 65ch;
}

.project {
  border: 1px solid #006c3a;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  padding: 5px;
  transition: border 0.3s ease-out;
}

.project-body {
  max-height: 50px;
}

.project:hover {
  border: 2px solid #00c64c;
  transition: color 0.3s ease-out;
}

.code-tech {
  color: #6c757d;
}

.project:hover .code-tech {
  color: #f5f5f5;
  transition: color 0.3s ease-out;
}

.code {
  color: #006c3a;
  font-size: 1.3rem;
  transition: color 0.3s ease-out;
}

.project:hover .code {
  color: #00c64c;
}

.resume {
  margin-bottom: 1.5rem;
  min-height: 100%;
}

.toggle-switch {
  background-color: #555555;
  border-radius: 5px;
  box-shadow: 0 1px 1px #666;
  color: rgb(222, 222, 222);
  margin: 1rem;
  transform: translateY(4px);
  transition-duration: 0.5s;
  width: 150px;
}

.toggle-switch:active{
  box-shadow: 0 1px 1px #b8b8b8;
  transform: translateY(8px);
}

.toggle-switch:hover {
  background-color: #a1a1a1;
  color:#1b2735;
  cursor: pointer;
}
