.box {
  border: 1px solid #006c3a;
  border-radius: 5px;
  transition: border 0.3s ease-out;
  padding: 10px;
  margin-bottom: 40px;
}

.box:hover {
  border: 1.5px solid #00c64c;
  transition: color 0.3s ease-out;
}

.fish {
  outline: 3px ridge rgb(142, 142, 142);
}
